"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoadCharacterScene = void 0;
const worldMetadata_1 = require("../worldMetadata");
class LoadCharacterScene extends Phaser.Scene {
    constructor() {
        super({ key: 'LoadCharacterScene' });
    }
    autoStart = false;
    init(data = {}) {
        if (data.autoStart) {
            this.autoStart = true;
        }
        else {
            this.autoStart = false;
        }
    }
    preload() { }
    create() {
        this.add.text(100, 100, 'Loading...');
        (0, worldMetadata_1.retrieveCharacter)().then(() => {
            console.log('character retrieved', worldMetadata_1.currentCharacter);
            this.scene.start('LoadWorldScene', { autoStart: this.autoStart });
        });
    }
}
exports.LoadCharacterScene = LoadCharacterScene;
