"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FrameScene = void 0;
const messageStack_1 = require("../components/messageStack");
const config_1 = require("../config");
class FrameScene extends Phaser.Scene {
    messageStack;
    constructor() {
        super({ key: 'FrameScene' });
    }
    preload() {
        this.load.image('overlayImage', 'static/frame.png');
    }
    create() {
        const overlayImage = this.add.image(config_1.SCREEN_WIDTH / 2, config_1.SCREEN_HEIGHT / 2, 'overlayImage'); // Adjust x, y and texture as needed
        overlayImage.setScrollFactor(0); // Prevent image from moving when scenes scroll
        //overlayImage.setScale(1.4814);
        console.log('FrameScene created');
        this.messageStack = new messageStack_1.MessageStack(this);
        // Add a sample message
        //this.messageStack.addMessage("Task completed!", 120); // 2 minutes
        //this.messageStack.addMessage("Starting soon...", 30); // 30 seconds
    }
}
exports.FrameScene = FrameScene;
