"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpriteItem = void 0;
const worldScene_1 = require("../scenes/worldScene");
const color_1 = require("../utils/color");
const palette_swapper_1 = require("./palette_swapper");
const item_1 = require("../world/item");
class SpriteItem extends item_1.Item {
    sprite;
    scene;
    priceText;
    outText;
    templateSprite;
    flat;
    hasPickedupFrame = false;
    healthBar;
    maxHealth;
    ownedByCommunity;
    ownedByCharacter;
    constructor(scene, item) {
        const itemType = scene.itemTypes[item.type];
        if (!itemType) {
            throw new Error(`Item type '${item.type}' does not exist in global item configuration.`);
        }
        super(worldScene_1.world, item.id, item.position, itemType);
        this.flat = this.itemType.flat == true;
        this.name = item.name;
        this.subtype = item.subtype;
        this.templateType = item.templateType;
        this.house = item.house;
        this.carried_by = item.carried_by;
        this.lock = item.lock;
        this.ownedByCommunity = item.ownedByCommunity;
        this.ownedByCharacter = item.ownedByCharacter;
        // copy over all attributes
        for (const key in item.attributes) {
            this.attributes[key] = item.attributes[key];
        }
        // Intialize health bar for smashable item types
        if (this.itemType.layout_type) {
            this.healthBar = scene.add.graphics();
            scene.itemTypes[item.type].attributes?.forEach((attribute) => {
                if (attribute['name'] == 'health') {
                    this.maxHealth = Number(attribute['value']);
                }
            });
        }
        let x, y;
        if (item.position) {
            [x, y] = [item.position.x, item.position.y];
        }
        else if (item.carried_by) {
            const pos = worldScene_1.world.mobs[item.carried_by].position;
            if (!pos) {
                throw new Error('Carried by doesnt exist');
            }
            [x, y] = [pos.x, pos.y];
        }
        else {
            // throw error as should never happen
            throw new Error('Item has no position or carrier');
        }
        this.sprite = scene.add.sprite(...scene.convertToWorldXY({ x, y }), scene.itemSource[item.type]);
        this.sprite.setScale(1);
        if (scene.textures.get(scene.itemSource[item.type]).has(item.type)) {
            const texture = scene.textures
                .get(scene.itemSource[item.type])
                .get(item.type);
            //console.log('Creating item', this.key, this.type, texture.width, texture.height);
            if (texture.height > 48) {
                this.sprite.setOrigin(0.5, 0.75);
            }
        }
        if (scene.textures
            .get(scene.itemSource[item.type])
            .has(item.type + '-picked-up')) {
            this.hasPickedupFrame = true;
        }
        this.scene = scene;
        if (this.subtype) {
            const parts = this.subtype.split('-');
            const color1 = Number(parts[0]);
            const color1dark = (0, color_1.darkenColor)(color1, 25);
            const paletteSwapper = palette_swapper_1.PaletteSwapper.getInstance();
            paletteSwapper.swapPalette(scene, 'global_atlas', `${this.type}`, `${this.type}-${this.subtype}`, { COLOR_1: color1, COLOR_1_DARK: color1dark });
            if (this.hasPickedupFrame) {
                paletteSwapper.swapPalette(scene, 'global_atlas', `${this.type}-picked-up`, `${this.type}-${this.subtype}-picked-up`, { COLOR_1: color1, COLOR_1_DARK: color1dark });
            }
        }
        if (this.itemType.show_price_at && 'price' in this.attributes) {
            this.priceText = scene.add
                .text(this.sprite.x + this.itemType.show_price_at.x, this.sprite.y + this.itemType.show_price_at.y, this.attributes['price'].toString(), { font: '10px Arial', color: '#000000' })
                .setOrigin(1);
            this.priceText.setDepth(this.position.y + 0.75);
        }
        if ('items' in this.attributes) {
            this.outText = scene.add
                .text(this.sprite.x, this.sprite.y, '∅', {
                fontFamily: 'Arial',
                fontSize: '30px',
                fontStyle: 'bold',
                color: '#FF0000',
                strokeThickness: 3,
                stroke: '#000000'
            })
                .setOrigin(0.5);
            this.outText.setDepth(this.position.y + 0.75);
        }
        if (this.templateType && this.itemType.show_template_at) {
            const [tx, ty] = scene.convertToWorldXY({ x, y });
            const xAdjust = this.itemType.show_template_at.x;
            const yAdjust = this.itemType.show_template_at.y;
            this.templateSprite = scene.add.sprite(tx + xAdjust, ty + yAdjust, scene.itemSource[this.templateType]);
            this.templateSprite.setScale(0.6);
        }
        this.animate();
        if (this.type === 'wall' ||
            this.type === 'door' ||
            this.type === 'fence' ||
            this.type === 'gate') {
            const above = worldScene_1.world.getItemAt(this.position.x, this.position.y - 1);
            const below = worldScene_1.world.getItemAt(this.position.x, this.position.y + 1);
            const left = worldScene_1.world.getItemAt(this.position.x - 1, this.position.y);
            const right = worldScene_1.world.getItemAt(this.position.x + 1, this.position.y);
            if (above) {
                above.animate();
            }
            if (below) {
                below.animate();
            }
            if (left) {
                left.animate();
            }
            if (right) {
                right.animate();
            }
        }
    }
    destroy(world) {
        super.destroy(world);
        this.healthBar?.destroy();
        this.sprite.destroy();
        this.priceText?.destroy();
        this.outText?.destroy();
        // Notify adjacent fences to re-evaluate their sprite states
        const adjacentPositions = [
            { x: this.position.x, y: this.position.y - 1 }, // above
            { x: this.position.x, y: this.position.y + 1 }, // below
            { x: this.position.x - 1, y: this.position.y }, // left
            { x: this.position.x + 1, y: this.position.y } // right
        ];
        adjacentPositions.forEach(({ x, y }) => {
            const adjacentItem = world.getItemAt(x, y);
            if (adjacentItem && this.sameItemGroup(adjacentItem)) {
                adjacentItem.animate(); // Recalculate fence sprite
            }
        });
    }
    sameItemGroup(item) {
        return (item != null &&
            item.itemType.item_group !== undefined &&
            item.itemType.item_group === this.itemType.item_group);
    }
    animate() {
        let animation_key;
        if (this.templateSprite && this.templateType) {
            this.templateSprite.setFrame(this.templateType);
        }
        if (this.itemType.layout_type === 'opens') {
            if (this.position) {
                const nearbyMobs = worldScene_1.world.getMobsAt(this.position.x, this.position.y, 2);
                if (nearbyMobs.some((mob) => mob.unlocks.includes(this.lock))) {
                    this.sprite.setFrame(`${this.type}-open`);
                }
                else {
                    this.sprite.setFrame(`${this.type}-closed`);
                }
            }
        }
        else if (this.itemType.layout_type === 'fence') {
            const above = this.sameItemGroup(worldScene_1.world.getItemAt(this.position.x, this.position.y - 1));
            const below = this.sameItemGroup(worldScene_1.world.getItemAt(this.position.x, this.position.y + 1));
            const left = this.sameItemGroup(worldScene_1.world.getItemAt(this.position.x - 1, this.position.y));
            const right = this.sameItemGroup(worldScene_1.world.getItemAt(this.position.x + 1, this.position.y));
            const animationKey = [this.type];
            if (below) {
                animationKey.push('b');
            }
            if (above) {
                animationKey.push('t');
            }
            if (right) {
                animationKey.push('r');
            }
            if (left) {
                animationKey.push('l');
            }
            animation_key = `${animationKey.join('_')}`;
            this.sprite.setFrame(animation_key);
        }
        else if (this.itemType.layout_type === 'wall') {
            const house = this.house ? worldScene_1.world.houses[this.house] : null;
            const above = this.sameItemGroup(worldScene_1.world.getItemAt(this.position.x, this.position.y - 1));
            const below = this.sameItemGroup(worldScene_1.world.getItemAt(this.position.x, this.position.y + 1));
            const left = this.sameItemGroup(worldScene_1.world.getItemAt(this.position.x - 1, this.position.y));
            const right = this.sameItemGroup(worldScene_1.world.getItemAt(this.position.x + 1, this.position.y));
            if (above && left) {
                this.sprite.setFrame(`${this.type}-bottom-right`);
            }
            else if (above && right) {
                this.sprite.setFrame(`${this.type}-bottom-left`);
            }
            else if (below && left) {
                this.sprite.setFrame(`${this.type}-top-right`);
            }
            else if (below && right) {
                this.sprite.setFrame(`${this.type}-top-left`);
            }
            else if (above || below) {
                if (house) {
                    if (house.top_left.x == this.position.x) {
                        this.sprite.setFrame(`${this.type}-left`);
                    }
                    else {
                        this.sprite.setFrame(`${this.type}-right`);
                    }
                }
                else {
                    this.sprite.setFrame(`${this.type}-right`);
                }
            }
            else {
                this.sprite.setFrame(`${this.type}-top`);
            }
        }
        else if (this.subtype) {
            if (this.carried_by) {
                animation_key = `${this.type}-${this.subtype}-picked-up`;
            }
            else {
                animation_key = `${this.type}-${this.subtype}`;
            }
            this.sprite.setTexture(animation_key);
        }
        else {
            if (this.hasPickedupFrame && this.carried_by) {
                animation_key = `${this.type}-picked-up`;
            }
            else {
                animation_key = `${this.type}`;
            }
            this.sprite.setFrame(animation_key);
        }
        if (this.priceText && this.attributes['price']) {
            this.priceText.setText(this.attributes['price'].toString());
        }
        if ('items' in this.attributes) {
            if (this.attributes['items'] > 0) {
                this.outText?.setVisible(false);
            }
            else {
                this.outText?.setVisible(true);
            }
        }
    }
    pickup(world, mob) {
        super.pickup(world, mob);
        this.animate();
    }
    stash(world, mob, position) {
        super.stash(world, mob, position);
        this.sprite.setDepth(0);
        [this.sprite.x, this.sprite.y] = [-1, -1];
        this.animate();
    }
    drop(world, mob, position) {
        super.drop(world, mob, position);
        this.sprite.setDepth(0);
        if (this.position) {
            [this.sprite.x, this.sprite.y] = this.scene.convertToWorldXY(this.position);
        }
        this.animate();
    }
    unstash(world, mob) {
        // Call base unstash to update world state
        super.unstash(world, mob);
        // Reposition and make sprite visible
        this.sprite.visible = true;
        this.animate();
    }
    calculateHealthPercentage() {
        return Number(this.attributes['health']) / this.maxHealth;
    }
    isBelowMaxHealth() {
        return Number(this.attributes['health']) < this.maxHealth;
    }
    updateHealthBar() {
        this.healthBar?.clear();
        if (this.isBelowMaxHealth()) {
            const barWidth = 40;
            const barHeight = 5;
            const healthPercentage = this.calculateHealthPercentage();
            const x = this.sprite.x - barWidth / 2;
            const y = this.sprite.y - 20;
            this.healthBar?.fillStyle(0xff0000);
            this.healthBar?.fillRect(x, y, barWidth, barHeight);
            this.healthBar?.fillStyle(0x00ff00);
            this.healthBar?.fillRect(x, y, barWidth * healthPercentage, barHeight);
            this.healthBar?.setDepth(1000);
        }
    }
    tick(world, deltaTime) {
        super.tick(world, deltaTime);
        if (this?.healthBar) {
            this.updateHealthBar();
        }
        if (this.position) {
            let depth = this.position.y + 0.5;
            if (this.flat) {
                depth = 0;
            }
            if (this.carried_by) {
                depth += 1;
            }
            this.sprite.setDepth(depth);
            if (this.templateSprite) {
                this.templateSprite?.setDepth(depth + 0.1);
            }
            if (this.type === 'door' || this.type === 'gate') {
                this.animate();
            }
        }
    }
}
exports.SpriteItem = SpriteItem;
