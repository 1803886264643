"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageStack = void 0;
class MessageComponent extends Phaser.GameObjects.Container {
    messageText;
    timerText;
    startTime;
    duration;
    constructor(scene, x, y, message, duration) {
        super(scene, x, y);
        this.duration = duration; // duration in seconds
        this.startTime = scene.time.now;
        // Create the background box
        const box = scene.add.rectangle(0, 0, 200, 50, 0x000000, 0.6);
        box.setOrigin(1, 0.5); // Align box to the right
        // Create the message text
        this.messageText = scene.add
            .text(-190, 0, message, {
            fontSize: '16px',
            color: '#ffffff'
        })
            .setOrigin(0, 0.5);
        // Create the timer text
        this.timerText = scene.add
            .text(-10, 0, '', {
            fontSize: '16px',
            color: '#ffffff'
        })
            .setOrigin(1, 0.5);
        // Add components to the container
        this.add([box, this.messageText, this.timerText]);
        // Update the timer every second
        scene.time.addEvent({
            delay: 1000,
            callback: this.updateTimer,
            callbackScope: this,
            loop: true
        });
    }
    updateTimer() {
        const elapsed = (this.scene.time.now - this.startTime) / 1000;
        const remaining = this.duration - elapsed;
        if (remaining > 90) {
            this.timerText.setText(`${Math.ceil(remaining / 60)}m`);
        }
        else if (remaining > 0) {
            this.timerText.setText(`${Math.ceil(remaining)}s`);
        }
        else {
            this.destroy(); // Remove component when time is up
        }
    }
}
class MessageStack {
    scene;
    stack = [];
    constructor(scene) {
        this.scene = scene;
    }
    addMessage(message, duration) {
        const xPosition = this.scene.scale.width - 10; // 10px from the right edge
        const yPosition = this.stack.length * 60 + 30; // Stack with 10px margin from the top
        const messageComponent = new MessageComponent(this.scene, xPosition, yPosition, message, duration);
        this.scene.add.existing(messageComponent);
        this.stack.push(messageComponent);
        // Remove from stack when destroyed
        messageComponent.once('destroy', () => {
            this.stack = this.stack.filter((msg) => msg !== messageComponent);
            this.repositionMessages();
        });
    }
    repositionMessages() {
        const xPosition = this.scene.scale.width - 10;
        this.stack.forEach((msg, index) => {
            msg.y = index * 60 + 30; // Reposition vertically with 10px top margin
            msg.x = xPosition;
        });
    }
}
exports.MessageStack = MessageStack;
