"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.publishPlayerMessage = publishPlayerMessage;
exports.publishChatMessage = publishChatMessage;
exports.requestChat = requestChat;
exports.requestFight = requestFight;
exports.speak = speak;
exports.chatPlayer = chatPlayer;
exports.fight = fight;
exports.interact = interact;
exports.startWorld = startWorld;
exports.updateWorld = updateWorld;
exports.leaveWorld = leaveWorld;
exports.publishPlayerPosition = publishPlayerPosition;
exports.publishPlayerStateToPersist = publishPlayerStateToPersist;
const common_1 = require("@rt-potion/common");
const worldScene_1 = require("../scenes/worldScene");
const worldMetadata_1 = require("../worldMetadata");
const ablySetup_1 = require("./ablySetup");
function publishPlayerMessage(type, payload) {
    ablySetup_1.playerChannel.publish(type, payload);
}
function publishChatMessage(payload) {
    ablySetup_1.chatChannel.publish('chat', payload);
}
function requestChat(mob) {
    publishPlayerMessage('chat_request', { mob_key: mob.key });
}
// Helper function for both player and NPC speech
function showSpeech(message, response) {
    if (response !== undefined) {
        publishPlayerMessage('speak', { response: response });
    }
    const player = worldScene_1.world.mobs[worldMetadata_1.publicCharacterId];
    player.showSpeechBubble(message, true);
}
function requestFight(mob) {
    publishPlayerMessage('fight_request', { mob_key: mob.key });
}
// Function for NPCs (includes a response)
function speak(message, response) {
    showSpeech(message, response);
}
// Function for players (only takes a message)
function chatPlayer(message) {
    showSpeech(message);
    publishChatMessage({ mob_id: worldMetadata_1.publicCharacterId, message });
}
function fight(message, attack) {
    publishPlayerMessage('fight', { attack });
    const player = worldScene_1.world.mobs[worldMetadata_1.publicCharacterId];
    player.showSpeechBubble(message, true);
}
function interact(item_key, action, give_to) {
    publishPlayerMessage('interact', {
        item_key: item_key,
        action: action,
        give_to: give_to
    });
}
function startWorld() {
    ablySetup_1.broadcastChannel.presence.enter(worldMetadata_1.publicCharacterId, (err) => {
        if (err) {
            console.error('Error entering presence:', err);
        }
        else {
            console.log('Successfully entered presence.');
            publishPlayerMessage('join', {
                name: worldMetadata_1.currentCharacter.name,
                subtype: worldMetadata_1.currentCharacter.subtype()
            });
        }
    });
}
// Triggers process to update the world associated with the player in supabase
function updateWorld(target_world_id) {
    const updateData = {
        publicCharacterId: worldMetadata_1.publicCharacterId,
        target_world_id: target_world_id
    };
    ablySetup_1.broadcastChannel.presence.update(updateData, (err) => {
        if (err) {
            console.error('Error updating world:', err);
        }
        else {
            console.log('Successfully updated world.');
        }
    });
}
/**
 * Broadcasts a leave event for the current world through the presence channel.
 * @param {string} target_world_id - The ID of the world to move to, from the worlds table in Supabase.
 * Can also be 'STAY_AT_WORLD' to indicate staying in the current world, as defined in serverToBroadcast.
 * @throws {Error} When there's an error leaving the presence channel
 */
function leaveWorld(target_world_id) {
    // Do not both updating when you are not changing worlds
    if (!target_world_id || 'MAINTAIN_WORLD_OPTION' === target_world_id) {
        return;
    }
    const leaveData = {
        publicCharacterId: worldMetadata_1.publicCharacterId,
        target_world_id: target_world_id
    };
    ablySetup_1.broadcastChannel.presence.leave(leaveData, (err) => {
        if (err) {
            console.error('Error leaving presence:', err);
        }
        else {
            console.log('Successfully left presence.');
        }
    });
}
function publishPlayerPosition(target) {
    if (ablySetup_1.playerChannel && target) {
        const flooredTarget = (0, common_1.floor)(target);
        const player = worldScene_1.world.mobs[worldMetadata_1.publicCharacterId];
        player.target = flooredTarget;
        const path = worldScene_1.world.generatePath(player.unlocks, player.position, flooredTarget);
        player.path = path;
        publishPlayerMessage('move', { target: flooredTarget });
        //console.log(`Publishing move to ${JSON.stringify(target)}`)
    }
}
// TODO: perhaps use this function in the case that we want to update player state on more than just leave
// topic 'update_state' is not currently subscribed to on server side
function publishPlayerStateToPersist() {
    if (ablySetup_1.playerChannel) {
        console.log('Requesting data persistence.');
        publishPlayerMessage('update_state', { name: worldMetadata_1.publicCharacterId });
    }
}
