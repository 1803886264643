"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNightSkyOpacity = getNightSkyOpacity;
const Phaser = __importStar(require("phaser"));
var NightOpacityConstants;
(function (NightOpacityConstants) {
    NightOpacityConstants[NightOpacityConstants["TIME_OFFSET"] = 9] = "TIME_OFFSET";
    NightOpacityConstants[NightOpacityConstants["PERIOD"] = 12] = "PERIOD";
    NightOpacityConstants[NightOpacityConstants["AMPLITUDE"] = 0.25] = "AMPLITUDE";
    NightOpacityConstants[NightOpacityConstants["INTERPOLATION_PERCENT"] = 0.01] = "INTERPOLATION_PERCENT";
    NightOpacityConstants[NightOpacityConstants["DEFAULT_OPACITY"] = 0] = "DEFAULT_OPACITY";
})(NightOpacityConstants || (NightOpacityConstants = {}));
/**
 * Calculates the opacity of the night sky based on the current in game time using a sinusoidal function
 * assuming a 12 hour clock cycle.
 * It has a maximum value of 0.5 (for darkest night) and minimum of 0 (for brightest day time).
 *
 * @param currentTime hour in game (0 - 11)
 * @param NightOpacity current target opacity for the night sky overlay
 * @returns    the new target opacity
 */
function getNightSkyOpacity(currentTime, NightOpacity) {
    let nextNightOpacity = 0;
    // Determines the opacity of the night overlay on the 12-hour clock cycle
    let sinExp = ((2 * Math.PI) / NightOpacityConstants.PERIOD) *
        (currentTime - NightOpacityConstants.TIME_OFFSET);
    nextNightOpacity =
        NightOpacityConstants.AMPLITUDE * Math.sin(sinExp) +
            NightOpacityConstants.AMPLITUDE;
    // Smooth transition by slowly approaching the opacity value
    const smoothOpacity = Phaser.Math.Interpolation.Linear([NightOpacity || NightOpacityConstants.DEFAULT_OPACITY, nextNightOpacity], NightOpacityConstants.INTERPOLATION_PERCENT);
    return smoothOpacity;
}
