"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.playerDead = void 0;
exports.setupBroadcast = setupBroadcast;
const main_1 = require("../main");
const worldScene_1 = require("../scenes/worldScene");
const controller_1 = require("../world/controller");
const worldMetadata_1 = require("../worldMetadata");
const playerToServer_1 = require("./playerToServer");
const leaderboardScene_1 = require("../scenes/leaderboardScene");
exports.playerDead = false;
//constant to indicate to server to have player remain in the current world
//must match MAINTAIN_WORLD_OPTION in server/src/services/clientCommunication/ablyService.ts
const MAINTAIN_WORLD_OPTION = 'NO_CHANGE';
function setupBroadcast(broadcast_channel, scene) {
    function handleAddMob(data) {
        if (!worldScene_1.world.mobs[data.id]) {
            (0, controller_1.addNewMob)(scene, data.mob);
        }
    }
    function handleAddItem(data) {
        if (!worldScene_1.world.items[data.id]) {
            (0, controller_1.addNewItem)(scene, data.item);
        }
    }
    function handleDestroyItem(data) {
        const item = worldScene_1.world.items[data.object_key];
        if (item) {
            item.destroy(worldScene_1.world);
        }
        if (data.mob_key && worldScene_1.world.mobs[data.mob_key]) {
            worldScene_1.world.mobs[data.mob_key].carrying = undefined;
        }
    }
    function handlePickupItem(data) {
        const item = worldScene_1.world.items[data.item_key];
        const mob = worldScene_1.world.mobs[data.mob_key];
        item.pickup(worldScene_1.world, mob);
    }
    function handleGiveItem(data) {
        const item = worldScene_1.world.items[data.item_key];
        const from = worldScene_1.world.mobs[data.from_key];
        const to = worldScene_1.world.mobs[data.to_key];
        item.giveItem(worldScene_1.world, from, to);
    }
    function handleDropItem(data) {
        const item = worldScene_1.world.items[data.item_key];
        const mob = worldScene_1.world.mobs[data.mob_key];
        item.drop(worldScene_1.world, mob, data.position);
    }
    function handleStashItem(data) {
        const item = worldScene_1.world.items[data.item_key];
        const mob = worldScene_1.world.mobs[data.mob_key];
        item.stash(worldScene_1.world, mob, data.position);
        (0, controller_1.updateInventory)();
    }
    function handleUnstashItem(data) {
        const item = worldScene_1.world.items[data.item_key];
        const mob = worldScene_1.world.mobs[data.mob_key];
        item.unstash(worldScene_1.world, mob);
        worldScene_1.world.removeStoredItem(item);
        (0, controller_1.updateInventory)();
    }
    function handleDoing(data) {
        const mob = worldScene_1.world.mobs[data.id];
        mob.doing = data.action;
    }
    function handleMove(data) {
        const mob = worldScene_1.world.mobs[data.id];
        if (mob) {
            if (data.id !== worldMetadata_1.publicCharacterId) {
                mob.target = data.target;
                mob.path = data.path;
            }
        }
        else {
            console.error('Mob not found for movement', data.id);
            throw new Error('Mob not found for movement');
        }
    }
    function handleDestroyMob(data) {
        const mob = worldScene_1.world.mobs[data.id];
        if (mob) {
            mob.destroy(worldScene_1.world);
            if (data.id === worldMetadata_1.publicCharacterId) {
                exports.playerDead = true;
                // wait until the window is focused before moving on
                const waitUntilFocused = new Promise((resolve) => {
                    const checkFocus = () => {
                        if (main_1.focused == true) {
                            // resolve once game is focused
                            resolve();
                        }
                        else {
                            // keep waiting
                            setTimeout(checkFocus, 100);
                        }
                    };
                    checkFocus();
                });
                // once game focused, leave the world and display game over
                waitUntilFocused.then(() => {
                    // in cases where player should stay in the same world, pass MAINTAIN_WORLD_OPTION
                    (0, playerToServer_1.leaveWorld)(MAINTAIN_WORLD_OPTION);
                    scene.showGameOver();
                });
            }
        }
    }
    function handlePortal(data) {
        const mob = worldScene_1.world.mobs[data.mob_key];
        if (mob?.key === worldMetadata_1.publicCharacterId) {
            mob.destroy(worldScene_1.world);
            scene.scene.stop('WorldScene');
        }
    }
    function handleMobChange(data) {
        const mob = worldScene_1.world.mobs[data.id];
        if (mob) {
            mob.changeAttribute(data.property, data.delta, data.new_value);
        }
    }
    function handleSpeak(data) {
        const mob = worldScene_1.world.mobs[data.id];
        if (mob) {
            mob.showSpeechBubble(data.message, false);
        }
    }
    function handleItemChange(data) {
        const item = worldScene_1.world.items[data.id];
        if (item) {
            item.attributes[data.property] = data.value;
            item.animate();
        }
    }
    function handleSetDatetime(data) {
        (0, controller_1.setDate)(data.date);
    }
    function handleShowPortalMenu(data) {
        if (data.mob_key === worldMetadata_1.publicCharacterId) {
            (0, controller_1.setAvailableWorlds)(data.worlds);
            scene.scene.launch('PortalMenuScene');
        }
    }
    function handleScoreboard(data) {
        (0, controller_1.setLeaderboardData)(data.scores);
        const leaderboardScene = scene.scene.get('LeaderboardScene');
        if (leaderboardScene instanceof leaderboardScene_1.LeaderboardScene) {
            if (scene.scene.isActive('LeaderboardScene')) {
                leaderboardScene.renderLeaderboard();
            }
            else if (scene.scene.get('LeaderboardScene')) {
                console.debug('LeaderboardScene is initialized but not active.');
            }
        }
        else {
            throw new Error('Leaderboard scene not found');
        }
    }
    function handleReloadPage() {
        sessionStorage.setItem('traveling_through_portal', 'true');
        window.location.reload();
    }
    function handleBomb(data) {
        const mob = worldScene_1.world.mobs[data.id];
        if (mob) {
            mob.createBombExplosion(1);
        }
    }
    // Subscribe to broadcast and dispatch events using switch
    broadcast_channel.subscribe('tick', (message) => {
        if (controller_1.gameState !== 'stateInitialized')
            return;
        const broadcastData = message.data.broadcast;
        for (const broadcastItem of broadcastData) {
            switch (broadcastItem.type) {
                case 'add_mob':
                    handleAddMob(broadcastItem.data);
                    break;
                case 'add_item':
                    handleAddItem(broadcastItem.data);
                    break;
                case 'destroy_item':
                    handleDestroyItem(broadcastItem.data);
                    break;
                case 'pickup_item':
                    handlePickupItem(broadcastItem.data);
                    break;
                case 'give_item':
                    handleGiveItem(broadcastItem.data);
                    break;
                case 'drop_item':
                    handleDropItem(broadcastItem.data);
                    break;
                case 'stash_item':
                    console.log(broadcastItem.data, 'BROADCAST STASH ITEM');
                    handleStashItem(broadcastItem.data);
                    break;
                case 'unstash_item':
                    console.log(broadcastItem.data, 'BROADCAST UNSTASH ITEM');
                    handleUnstashItem(broadcastItem.data);
                case 'doing':
                    handleDoing(broadcastItem.data);
                    break;
                case 'move':
                    handleMove(broadcastItem.data);
                    break;
                case 'destroy_mob':
                    handleDestroyMob(broadcastItem.data);
                    break;
                case 'portal':
                    handlePortal(broadcastItem.data);
                    break;
                case 'mob_change':
                    handleMobChange(broadcastItem.data);
                    break;
                case 'speak':
                    handleSpeak(broadcastItem.data);
                    break;
                case 'item_change':
                    handleItemChange(broadcastItem.data);
                    break;
                case 'set_datetime':
                    handleSetDatetime(broadcastItem.data);
                    break;
                case 'show_portal_menu':
                    handleShowPortalMenu(broadcastItem.data);
                    break;
                case 'scoreboard':
                    handleScoreboard(broadcastItem.data);
                    break;
                case 'reload_page':
                    handleReloadPage();
                    break;
                case 'bomb':
                    handleBomb(broadcastItem.data);
                    break;
                default:
                    console.error(`No handler found for type: ${JSON.stringify(broadcastItem)}`);
            }
        }
    });
}
