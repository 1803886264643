"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chatChannel = exports.playerChannel = exports.broadcastChannel = void 0;
exports.setupAbly = setupAbly;
exports.bindAblyToWorldScene = bindAblyToWorldScene;
const ably_1 = require("ably");
const worldScene_1 = require("../scenes/worldScene");
const worldMetadata_1 = require("../worldMetadata");
const serverToPlayer_1 = require("./serverToPlayer");
const serverToBroadcast_1 = require("./serverToBroadcast");
let isNewName;
const SERVER_URL = process.env.SERVER_URL; //Cannot use getEnv in the client package https://webpack.js.org/guides/environment-variables/
let channelsBoundToWorld = false;
function setupAbly(changedName = false) {
    isNewName = changedName;
    let authorizer = SERVER_URL.slice(-1) == '/' ? 'auth?username=' : '/auth?username=';
    let worldID;
    return new Promise((resolve, _reject) => {
        const authUrl = SERVER_URL + authorizer + worldMetadata_1.characterId;
        const ably = new ably_1.Realtime({
            authCallback: (tokenParams, callback) => {
                fetch(authUrl)
                    .then((response) => response.json())
                    .then((data) => {
                    worldID = data.worldID;
                    callback(null, data.tokenRequest);
                })
                    .catch((err) => {
                    console.error('Error fetching auth token:', err);
                });
            }
        });
        ably.connection.on('connected', () => {
            console.log('Connected to Ably');
            exports.broadcastChannel = ably.channels.get(`world-${worldID}`);
            if (isNewName && exports.playerChannel) {
                exports.playerChannel.unsubscribe();
            }
            exports.playerChannel = ably.channels.get(`${worldMetadata_1.publicCharacterId}-${worldID}`);
            exports.chatChannel = ably.channels.get(`chat-${worldID}`);
            resolve(worldID);
            console.log('Ably client initialized successfully.', worldID);
        });
    });
}
function bindAblyToWorldScene(scene) {
    if (channelsBoundToWorld) {
        if (isNewName) {
            (0, serverToPlayer_1.setupPlayerSubscriptions)(exports.playerChannel, scene);
        }
        return;
    }
    channelsBoundToWorld = true;
    (0, serverToBroadcast_1.setupBroadcast)(exports.broadcastChannel, scene);
    (0, serverToPlayer_1.setupPlayerSubscriptions)(exports.playerChannel, scene);
    exports.chatChannel.subscribe('chat', (payload) => {
        const mob_id = payload.data.mob_id;
        if (worldMetadata_1.publicCharacterId === mob_id)
            return;
        const mob = worldScene_1.world.mobs[mob_id];
        if (mob) {
            mob.showSpeechBubble(payload.data.message, true);
        }
    });
}
