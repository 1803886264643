"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SCREEN_HEIGHT = exports.SCREEN_WIDTH = void 0;
const Phaser = __importStar(require("phaser"));
const worldScene_1 = require("./scenes/worldScene");
const uxScene_1 = require("./scenes/uxScene");
const loadWorldScene_1 = require("./scenes/loadWorldScene");
const loadCharacterScene_1 = require("./scenes/loadCharacterScene");
const frameScene_1 = require("./scenes/frameScene");
const pauseScene_1 = require("./scenes/pauseScene");
const chatOverlayScene_1 = require("./scenes/chatOverlayScene");
const brewScene_1 = require("./scenes/brewScene");
const fightScene_1 = require("./scenes/fightScene");
const portalMenuScene_1 = require("./scenes/portalMenuScene");
const leaderboardScene_1 = require("./scenes/leaderboardScene");
const miniLeaderboardScene_1 = require("./scenes/miniLeaderboardScene");
const portalLoadingScene_1 = require("./scenes/portalLoadingScene");
exports.SCREEN_WIDTH = 480;
exports.SCREEN_HEIGHT = 720;
const config = {
    type: Phaser.AUTO,
    width: exports.SCREEN_WIDTH,
    height: exports.SCREEN_HEIGHT,
    parent: 'game-container',
    scale: {
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_HORIZONTALLY
    },
    backgroundColor: '#ffffff',
    roundPixels: true,
    dom: {
        createContainer: true
    },
    scene: [
        loadCharacterScene_1.LoadCharacterScene,
        loadWorldScene_1.LoadWorldScene,
        pauseScene_1.PauseScene,
        worldScene_1.WorldScene,
        uxScene_1.UxScene,
        frameScene_1.FrameScene,
        chatOverlayScene_1.ChatOverlayScene,
        brewScene_1.BrewScene,
        fightScene_1.FightScene,
        portalMenuScene_1.PortalMenuScene,
        portalLoadingScene_1.PortalLoadingScene,
        leaderboardScene_1.LeaderboardScene,
        miniLeaderboardScene_1.MiniLeaderboardScene
    ]
};
exports.default = config;
