"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrewScene = void 0;
const messageStack_1 = require("../components/messageStack");
const config_1 = require("../config");
class BrewScene extends Phaser.Scene {
    messageStack;
    brewColor = 0x9eb9d4;
    numIngredients = 0;
    constructor() {
        super({ key: 'BrewScene' });
    }
    preload() {
        this.load.image('brewImage', 'static/potion-brewing.png');
    }
    create() {
        const barBackground = this.add.graphics();
        barBackground.fillStyle(0x9eb9d4, 1);
        barBackground.fillRect(180, 290, this.game.scale.width * 0.4, this.game.scale.height * -0.3);
        const barBackgroundColored = this.add.graphics();
        barBackgroundColored.fillStyle(this.brewColor, 1);
        barBackgroundColored.fillRect(180, 290, this.game.scale.width * 0.4, this.game.scale.height * -0.1 * this.numIngredients);
        const circleBackground = this.add.graphics();
        circleBackground.fillStyle(this.brewColor, 1);
        circleBackground.fillRect(90, 90, this.game.scale.width * 0.4, this.game.scale.height * 0.3);
        const overlayImage = this.add.image(config_1.SCREEN_WIDTH / 2, config_1.SCREEN_HEIGHT / 2, 'brewImage'); // Adjust x, y and texture as needed
        overlayImage.setScrollFactor(0); // Prevent image from moving when scenes scroll
        console.log('BrewScene created');
        this.messageStack = new messageStack_1.MessageStack(this);
    }
    setBrewColor(color) {
        this.brewColor = color;
    }
    setNumIngredients(num) {
        this.numIngredients = num;
    }
}
exports.BrewScene = BrewScene;
