"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.refreshCallback = exports.currentCharacter = exports.Character = exports.worldID = exports.publicCharacterId = exports.characterId = void 0;
exports.addRefreshCallback = addRefreshCallback;
exports.refresh = refresh;
exports.changeName = changeName;
exports.retrieveCharacter = retrieveCharacter;
exports.getWorldID = getWorldID;
exports.setWorldID = setWorldID;
exports.saveColors = saveColors;
const uuid_1 = require("uuid");
const color_1 = require("./utils/color");
const worldScene_1 = require("./scenes/worldScene");
const ablySetup_1 = require("./services/ablySetup");
class Character {
    name;
    eyeColor;
    furColor;
    bellyColor;
    community_id;
    constructor(name, eyeColor, furColor, bellyColor, community_id) {
        this.name = name;
        this.eyeColor = eyeColor;
        this.furColor = furColor;
        this.bellyColor = bellyColor;
        this.community_id = community_id;
    }
    get gold() {
        if (!worldScene_1.world || !worldScene_1.world.mobs[exports.publicCharacterId]) {
            return 0;
        }
        return worldScene_1.world.mobs[exports.publicCharacterId].attributes['gold'];
    }
    get health() {
        if (!worldScene_1.world || !worldScene_1.world.mobs[exports.publicCharacterId]) {
            return 0;
        }
        return worldScene_1.world.mobs[exports.publicCharacterId].attributes['health'];
    }
    get attack() {
        if (!worldScene_1.world || !worldScene_1.world.mobs[exports.publicCharacterId]) {
            return 0;
        }
        return worldScene_1.world.mobs[exports.publicCharacterId].attributes['attack'];
    }
    get defense() {
        if (!worldScene_1.world || !worldScene_1.world.mobs[exports.publicCharacterId]) {
            return 0;
        }
        return worldScene_1.world.mobs[exports.publicCharacterId].attributes['defense'];
    }
    get speed() {
        if (!worldScene_1.world || !worldScene_1.world.mobs[exports.publicCharacterId]) {
            return 0;
        }
        return worldScene_1.world.mobs[exports.publicCharacterId].attributes['speed'];
    }
    get stubbornness() {
        if (!worldScene_1.world || !worldScene_1.world.mobs[exports.publicCharacterId]) {
            return 0;
        }
        return worldScene_1.world.mobs[exports.publicCharacterId].personalities['stubbornness'];
    }
    get bravery() {
        if (!worldScene_1.world || !worldScene_1.world.mobs[exports.publicCharacterId]) {
            return 0;
        }
        return worldScene_1.world.mobs[exports.publicCharacterId].personalities['bravery'];
    }
    get aggression() {
        if (!worldScene_1.world || !worldScene_1.world.mobs[exports.publicCharacterId]) {
            return 0;
        }
        return worldScene_1.world.mobs[exports.publicCharacterId].personalities['aggression'];
    }
    get industriousness() {
        if (!worldScene_1.world || !worldScene_1.world.mobs[exports.publicCharacterId]) {
            return 0;
        }
        return worldScene_1.world.mobs[exports.publicCharacterId].personalities['industriousness'];
    }
    get adventurousness() {
        if (!worldScene_1.world || !worldScene_1.world.mobs[exports.publicCharacterId]) {
            return 0;
        }
        return worldScene_1.world.mobs[exports.publicCharacterId].personalities['adventurousness'];
    }
    get gluttony() {
        if (!worldScene_1.world || !worldScene_1.world.mobs[exports.publicCharacterId]) {
            return 0;
        }
        return worldScene_1.world.mobs[exports.publicCharacterId].personalities['gluttony'];
    }
    get sleepy() {
        if (!worldScene_1.world || !worldScene_1.world.mobs[exports.publicCharacterId]) {
            return 0;
        }
        return worldScene_1.world.mobs[exports.publicCharacterId].personalities['sleepy'];
    }
    get extroversion() {
        if (!worldScene_1.world || !worldScene_1.world.mobs[exports.publicCharacterId]) {
            return 0;
        }
        return worldScene_1.world.mobs[exports.publicCharacterId].personalities['extroversion'];
    }
    get favorabilities() {
        if (!worldScene_1.world || !worldScene_1.world.mobs[exports.publicCharacterId]) {
            return {};
        }
        return worldScene_1.world.mobs[exports.publicCharacterId].favorabilities;
    }
    get isCarrying() {
        if (!worldScene_1.world || !worldScene_1.world.mobs[exports.publicCharacterId]) {
            return undefined;
        }
        return worldScene_1.world.mobs[exports.publicCharacterId].carrying;
    }
    subtype() {
        return `${this.eyeColor}-${this.bellyColor}-${this.furColor}`;
    }
}
exports.Character = Character;
exports.currentCharacter = null;
function addRefreshCallback(callback) {
    exports.refreshCallback = callback;
}
async function refresh() {
    (0, exports.refreshCallback)();
}
async function changeName(name) {
    if (localStorage.getItem('name') !== name) {
        exports.characterId = (0, uuid_1.v4)();
        exports.publicCharacterId = exports.characterId.substr(0, 8);
        localStorage.setItem('characterId', exports.characterId);
        (0, ablySetup_1.setupAbly)(true);
    }
    localStorage.setItem('name', name);
    exports.currentCharacter.name = name;
}
async function retrieveCharacter() {
    exports.characterId = localStorage.getItem('characterId') || (0, uuid_1.v4)();
    exports.publicCharacterId = exports.characterId.substr(0, 8);
    localStorage.setItem('characterId', exports.characterId);
    exports.currentCharacter = new Character(localStorage.getItem('name') || 'Nobody', (0, color_1.hexStringToNumber)(localStorage.getItem('eyeColor') || (0, color_1.getRandomColor)()), (0, color_1.hexStringToNumber)(localStorage.getItem('furColor') || (0, color_1.getRandomColor)()), (0, color_1.hexStringToNumber)(localStorage.getItem('bellyColor') || (0, color_1.getRandomColor)()), 'alchemists');
    saveColors();
}
function getWorldID() {
    return exports.worldID;
}
function setWorldID(worldName) {
    exports.worldID = worldName;
}
async function saveColors() {
    localStorage.setItem('eyeColor', (0, color_1.numberToHexString)(exports.currentCharacter.eyeColor));
    localStorage.setItem('furColor', (0, color_1.numberToHexString)(exports.currentCharacter.furColor));
    localStorage.setItem('bellyColor', (0, color_1.numberToHexString)(exports.currentCharacter.bellyColor));
}
