"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseWorldFromJson = parseWorldFromJson;
function parseWorldFromJson(globaljson, specificjson) {
    const globalDescription = JSON.parse(JSON.stringify(globaljson));
    const specificDescription = JSON.parse(JSON.stringify(specificjson));
    const worldDescripton = {
        ...globalDescription,
        ...specificDescription
    };
    return worldDescripton;
}
