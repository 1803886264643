"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Item = void 0;
const physical_1 = require("./physical");
class Item extends physical_1.Physical {
    name;
    carried_by;
    currently_at = [];
    itemType;
    attributes = {};
    a;
    templateType;
    house;
    lock;
    ownedByCommunity;
    ownedByCharacter;
    constructor(world, key, position, itemType, ownedByCommunity, ownedByCharacter) {
        super(world, key, itemType.type, position);
        this.itemType = itemType;
        this.ownedByCommunity = ownedByCommunity;
        this.ownedByCharacter = ownedByCharacter;
        if (position) {
            world.addItemToGrid(this);
        }
    }
    isWalkable(unlocks) {
        if (this.lock) {
            return unlocks.includes(this.lock);
        }
        return this.itemType.walkable ? true : false;
    }
    isOwnedByCommunity(community_id) {
        return this.ownedByCommunity === community_id;
    }
    isOwnedByCharacter(character_id) {
        return this.ownedByCharacter === character_id;
    }
    destroy(world) {
        if (this.position) {
            world.removeItemFromGrid(this);
        }
        if (this.carried_by) {
            world.mobs[this.carried_by].carrying = undefined;
        }
        delete world.items[this.key];
    }
    giveItem(world, from, to) {
        if (!from.carrying) {
            throw new Error('Mob is not carrying anything');
        }
        if (to.carrying) {
            throw new Error('Mob is already carrying something');
        }
        if (this.carried_by !== from.key) {
            throw new Error('Item is not being carried by mob');
        }
        from.carrying = undefined;
        to.carrying = this.key;
        this.carried_by = to.key;
        this.position = null;
    }
    pickup(world, mob) {
        mob.carrying = this.key;
        this.carried_by = mob.key;
        if (this.position) {
            world.removeItemFromGrid(this);
        }
        this.position = null;
    }
    drop(world, mob, position) {
        if (!mob.position) {
            throw new Error('Mob has no position');
        }
        mob.carrying = undefined;
        this.carried_by = undefined;
        this.position = position;
        world.addItemToGrid(this);
        // place in position determined by server
    }
    stash(world, mob, position) {
        if (!this.carried_by) {
            throw new Error('Must carry item being stashed.');
        }
        console.log('stashing item', this.key, this.carried_by);
        mob.carrying = undefined;
        this.position = position;
        world.removeItemFromGrid(this);
        this.position = null;
        this.carried_by = undefined;
        world.addStoredItem(this); // Add to stored items
    }
    unstash(world, mob) {
        if (!mob.position) {
            throw new Error('Mob has no position');
        }
        this.carried_by = mob.key;
        mob.carrying = this.key;
        world.removeStoredItem(this); // Remove from stored items
    }
    tick(world, deltaTime) {
        super.tick(world, deltaTime);
    }
    conditionMet(interaction) {
        let conditionsMet = true;
        if (interaction.conditions) {
            interaction.conditions.forEach((condition) => {
                const current_value = this.attributes[condition.attribute_name];
                switch (condition.comparison) {
                    case 'equals':
                        conditionsMet = conditionsMet && current_value === condition.value;
                        break;
                    case 'greater_than':
                        conditionsMet = conditionsMet && current_value > condition.value;
                        break;
                    case 'less_than':
                        conditionsMet = conditionsMet && current_value < condition.value;
                        break;
                    case 'greater_than_or_equal':
                        conditionsMet = conditionsMet && current_value >= condition.value;
                        break;
                    case 'less_than_or_equal':
                        conditionsMet = conditionsMet && current_value <= condition.value;
                        break;
                }
            });
        }
        return conditionsMet;
    }
}
exports.Item = Item;
