"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonManager = void 0;
class ButtonManager {
    buttons;
    constructor(buttons) {
        this.buttons = buttons;
    }
    push(object) {
        this.buttons.push(object);
    }
    clearButtonOptions() {
        this.buttons.forEach((button) => button.destroy());
        this.buttons = [];
    }
    // Takes a texture string as an argument and removes all buttons that do not have that texture.
    clearUnmatchedButtons(texture) {
        this.buttons.forEach((button) => {
            if (button.texture !== texture) {
                button.destroy();
            }
        });
    }
}
exports.ButtonManager = ButtonManager;
