"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Physical = void 0;
class Physical {
    position = null;
    angle = 0;
    dead = false;
    key;
    type;
    subtype = null;
    constructor(world, key, type, position) {
        this.key = key;
        this.type = type;
        if (position) {
            this.position = { x: position.x, y: position.y };
        }
    }
    tick(_world, _deltaTime) {
        if (this.dead) {
            return;
        }
    }
}
exports.Physical = Physical;
