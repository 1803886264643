"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Mob = void 0;
const common_1 = require("@rt-potion/common");
const physical_1 = require("./physical");
// If any new mobs are added that you do not want to chat, add it to the list below.
const notChattableTypes = ['player', 'blob'];
class Mob extends physical_1.Physical {
    _target;
    path = [];
    name;
    maxHealth;
    dead = false;
    carrying;
    attributes = {};
    personalities = {};
    favorabilities = {};
    unlocks = [];
    doing = '';
    community_id;
    chattable = true;
    constructor(world, key, name, type, maxHealth, position, attributes, personalities, favorabilities, community_id) {
        super(world, key, type, position);
        this.name = name;
        this.maxHealth = maxHealth;
        if (notChattableTypes.includes(type)) {
            this.chattable = false;
        }
        if (position) {
            world.addMobToGrid(this);
        }
        for (const [key, value] of Object.entries(attributes)) {
            this.attributes[key] = value;
        }
        for (const [key, value] of Object.entries(personalities)) {
            this.personalities[key] = value;
        }
        for (const [key, value] of Object.entries(favorabilities)) {
            this.favorabilities[key] = value;
        }
        if (community_id) {
            this.community_id = community_id;
        }
    }
    set target(value) {
        this._target = value;
    }
    get target() {
        return this._target;
    }
    destroy(world) {
        this.dead = true;
        if (this.position) {
            world.removeMobFromGrid(this);
        }
        delete world.mobs[this.key];
    }
    changePosition(world, newPosition) {
        world.moveMob(this, newPosition);
        this.position = newPosition;
    }
    tick(world, deltaTime) {
        super.tick(world, deltaTime);
        if (!this.position) {
            return;
        }
        if (this.path.length > 0) {
            // check if next step is blocked
            const nextStep = this.path[0];
            const lastStep = this.path[this.path.length - 1];
            const nextItem = world.getItemAt(nextStep.x, nextStep.y);
            if (!!nextItem && !nextItem.isWalkable(this.unlocks)) {
                this.path = world.generatePath(this.unlocks, this.position, lastStep);
            }
            const [position, angle] = (0, common_1.followPath)(this.position, this.path, this.attributes['speed'], deltaTime);
            this.changePosition(world, position);
            this.angle = angle;
        }
        if (this.path.length === 0 &&
            this.target &&
            this.position &&
            this.target.x == this.position.x &&
            this.target.y == this.position.y) {
            this.target = undefined;
        }
    }
}
exports.Mob = Mob;
