"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.leaderboardData = exports.availableWorlds = exports.gameState = exports.fantasyDate = exports.currentInteractions = void 0;
exports.setAvailableWorlds = setAvailableWorlds;
exports.setGameState = setGameState;
exports.setLeaderboardData = setLeaderboardData;
exports.setChatting = setChatting;
exports.setFighting = setFighting;
exports.setResponseCallback = setResponseCallback;
exports.setAttackCallback = setAttackCallback;
exports.setResponses = setResponses;
exports.setAttacks = setAttacks;
exports.initializePlayer = initializePlayer;
exports.tick = tick;
exports.areListsEqual = areListsEqual;
exports.mobRangeListener = mobRangeListener;
exports.getCarriedItemInteractions = getCarriedItemInteractions;
exports.getPhysicalInteractions = getPhysicalInteractions;
exports.getClosestPhysical = getClosestPhysical;
exports.getInteractablePhysicals = getInteractablePhysicals;
exports.setChatCompanionCallback = setChatCompanionCallback;
exports.setInteractionCallback = setInteractionCallback;
exports.setFightOpponentCallback = setFightOpponentCallback;
exports.setInventoryCallback = setInventoryCallback;
exports.updateInventory = updateInventory;
exports.addNewHouse = addNewHouse;
exports.addNewMob = addNewMob;
exports.setDate = setDate;
exports.addNewItem = addNewItem;
const worldScene_1 = require("../scenes/worldScene");
const common_1 = require("@rt-potion/common");
const sprite_mob_1 = require("../sprite/sprite_mob");
const sprite_item_1 = require("../sprite/sprite_item");
const worldScene_2 = require("../scenes/worldScene");
const worldMetadata_1 = require("../worldMetadata");
const sprite_house_1 = require("../sprite/sprite_house");
const playerToServer_1 = require("../services/playerToServer");
const MAX_STASH = 12;
let interactionCallback;
let chatCompanionCallback;
let fightOpponentCallback;
let lastInteractions = [];
let lastChatCompanions = [];
let lastFightOpponents = [];
let chatting = false;
let fighting = false;
let inventoryCallback;
exports.currentInteractions = [];
let responseCallback = () => { };
let attackCallback = () => { };
exports.gameState = 'uninitialized';
exports.availableWorlds = [];
exports.leaderboardData = [];
function setAvailableWorlds(worlds) {
    exports.availableWorlds = worlds;
}
function setGameState(state) {
    console.log('Setting game state to:', state);
    exports.gameState = state;
}
function setLeaderboardData(data) {
    exports.leaderboardData = data;
}
function setChatting(chat) {
    console.log('setChatting', chat);
    chatting = chat;
    // Allows for rechatting with the same NPC
    if (!chat) {
        lastChatCompanions = [];
    }
}
function setFighting(fight) {
    console.log('setFighting', fight);
    fighting = fight;
    // Allows for refighting with the same NPC
    if (!fight) {
        lastFightOpponents = [];
    }
}
function setResponseCallback(callback) {
    responseCallback = callback;
}
function setAttackCallback(callback) {
    attackCallback = callback;
}
function setResponses(responses) {
    responseCallback(responses);
}
function setAttacks(attacks) {
    attackCallback(attacks);
}
function initializePlayer() {
    if (worldMetadata_1.currentCharacter) {
        console.log('joining', worldMetadata_1.currentCharacter, worldMetadata_1.currentCharacter.name);
        (0, playerToServer_1.startWorld)();
    }
}
function tick(scene) {
    worldScene_2.world.tick(scene.game.loop.delta);
}
function areInteractionsEqual(lastInteractions, currentInteractions) {
    // Check if arrays have the same length
    if (lastInteractions.length !== currentInteractions.length) {
        return false;
    }
    // Check if each element in the arrays is equal
    for (let i = 0; i < lastInteractions.length; i++) {
        const last = lastInteractions[i];
        const current = currentInteractions[i];
        // Compare both the item_key and action
        if (last.item.key !== current.item.key || last.action !== current.action) {
            return false;
        }
    }
    // If all elements are equal, return true
    return true;
}
function areListsEqual(list1, list2) {
    if (list1.length !== list2.length) {
        return false;
    }
    return list1.every((item, index) => item.key === list2[index].key);
}
function mobRangeListener(mobs) {
    if (chatCompanionCallback && !chatting) {
        const filteredMobs = mobs.filter((mob) => mob.chattable);
        filteredMobs.sort((a, b) => a.key.localeCompare(b.key));
        if (!areListsEqual(filteredMobs, lastChatCompanions)) {
            chatCompanionCallback(filteredMobs);
            lastChatCompanions = filteredMobs;
        }
    }
    if (fightOpponentCallback && !fighting) {
        const filteredMobs = mobs.filter((mob) => mob.type !== 'player');
        filteredMobs.sort((a, b) => a.key.localeCompare(b.key));
        if (!areListsEqual(filteredMobs, lastFightOpponents)) {
            fightOpponentCallback(filteredMobs);
            lastFightOpponents = filteredMobs;
        }
    }
}
function prepInteraction(label, item) {
    if (!item.templateType) {
        return label;
    }
    else {
        return label.replace('$item_name', item.templateType || '');
    }
}
function getCarriedItemInteractions(item, nearbyItems, nearbyMobs, playerId) {
    const interactions = [];
    interactions.push({
        action: 'drop',
        item: item,
        label: `Drop ${item.itemType.name}`
    });
    if (worldScene_2.world?.getStoredItems().length < MAX_STASH) {
        interactions.push({
            action: 'stash',
            item: item,
            label: `Stash ${item.itemType.name}`
        });
    }
    // give to nearby mobs
    nearbyMobs.forEach((mob) => {
        if (mob.key !== playerId && !mob.carrying) {
            interactions.push({
                action: 'give',
                item: item,
                give_to: mob.key,
                label: `Give ${item.itemType.name} to ${mob.name}`
            });
        }
    });
    // unique carried item interactions
    item.itemType.interactions.forEach((interaction) => {
        if (interaction.while_carried) {
            const requiredItem = interaction.requires_item
                ? nearbyItems.find((i) => i.itemType.type === interaction.requires_item)
                : true;
            if ((!interaction.requires_item || requiredItem) &&
                item.conditionMet(interaction)) {
                interactions.push({
                    action: interaction.action,
                    item: item,
                    label: prepInteraction(interaction.description, item)
                });
            }
        }
    });
    return interactions;
}
function getPhysicalInteractions(physical, carried, community_id, character_id) {
    const interactions = [];
    const item = physical;
    const isOwnedByCharacter = item.isOwnedByCharacter(character_id);
    const isOwnedByCommunity = item.isOwnedByCommunity(community_id);
    // if the item can be picked up
    if (item.itemType.carryable) {
        interactions.push({
            action: 'pickup',
            item: item,
            label: `Pick up ${item.itemType.name}`
        });
    }
    // if the item can be smashed
    if (item.itemType.smashable) {
        interactions.push({
            action: 'smash',
            item: item,
            label: `Smash ${item.itemType.name}`
        });
    }
    // handles unique interactions
    item.itemType.interactions.forEach((interaction) => {
        const hasPermission = !interaction.permissions || // Allow interaction if no permissions entry in global.json
            (isOwnedByCommunity && interaction.permissions?.community) ||
            (isOwnedByCharacter && interaction.permissions?.character) ||
            (!isOwnedByCharacter &&
                !isOwnedByCommunity &&
                interaction.permissions?.other); // Allowed only for non-owners
        if (hasPermission &&
            !interaction.while_carried &&
            item.conditionMet(interaction)) {
            if ((interaction.action == 'add_item' &&
                carried &&
                carried.type.localeCompare(item.attributes.templateType.toString()) === 0) ||
                interaction.action != 'add_item') {
                interactions.push({
                    action: interaction.action,
                    item: item,
                    label: prepInteraction(interaction.description, item)
                });
            }
        }
    });
    return interactions;
}
function getClosestPhysical(physicals, playerPos) {
    const a = physicals.reduce((closest, current) => {
        if (!closest.position || !current.position)
            return closest;
        const closestDistance = (0, common_1.calculateDistance)(closest.position, playerPos);
        const currentDistance = (0, common_1.calculateDistance)(current.position, playerPos);
        return currentDistance < closestDistance ? current : closest;
    });
    return a;
}
function getItemsAtPosition(physicals, position) {
    return physicals.filter((physical) => {
        return (position.x === physical.position.x && position.y === physical.position.y);
    });
}
function getInteractablePhysicals(physicals, playerPos) {
    // player is standing on
    let onTopObjects = getItemsAtPosition(physicals, playerPos);
    // nearby "openable" items
    let nearbyOpenableObjects = physicals.filter((p) => p.itemType.layout_type === 'opens');
    if (nearbyOpenableObjects.length > 1) {
        nearbyOpenableObjects = [
            getClosestPhysical(nearbyOpenableObjects, playerPos)
        ];
    }
    // nearby non-walkable items
    let nearbyObjects = physicals.filter((p) => !p.itemType.walkable);
    let nearbyBaskets = physicals.filter((p) => p.itemType.type === 'basket');
    let objectsWithDistance = nearbyObjects.map((object) => {
        // Because this is a list of all of the objects in cardinal directions,
        // to get nearbyObjects, these objects needed to have had a valid position.
        // Thus, in the case that the object doesn't have a position, an error
        // should be thrown.
        if (!object.position)
            throw new TypeError(`Expected 'object.position' to be 'Coord', but received NULL`);
        return {
            object: object,
            distance: (0, common_1.calculateDistance)(object.position, playerPos)
        };
    });
    objectsWithDistance.sort((a, b) => a.distance - b.distance);
    // find distinct non-walkable objects next to player
    let unique_nearbyObjects = objectsWithDistance.filter((item, index, self) => index ===
        self.findIndex((i) => i.object.itemType === item.object.itemType));
    let nearestUniqueObjects = unique_nearbyObjects.map((obj) => obj.object);
    // enforce unique items
    let interactableObjects = [
        ...onTopObjects,
        ...nearestUniqueObjects,
        ...nearbyOpenableObjects,
        ...nearbyBaskets
    ];
    interactableObjects = interactableObjects.filter((item, index, self) => index ===
        self.findIndex((t) => t.key === item.key && t.position === item.position));
    return interactableObjects;
}
function collisionListener(physicals) {
    const player = worldScene_2.world.mobs[worldMetadata_1.publicCharacterId];
    const playerPos = (0, common_1.floor)(player.position);
    // retrieves a list of all of the nearby and on top of objects
    let interactableObjects = getInteractablePhysicals(physicals, playerPos);
    let interactions = [];
    let carriedItem = undefined;
    // if player is carrying object, add its according interactions
    if (player.carrying) {
        carriedItem = worldScene_2.world.items[player.carrying];
        const nearbyMobs = worldScene_2.world.getMobsAt(playerPos.x, playerPos.y, 2);
        const carriedInteractions = getCarriedItemInteractions(carriedItem, interactableObjects, nearbyMobs, worldMetadata_1.publicCharacterId);
        interactions = [...interactions, ...carriedInteractions];
    }
    // retrieves interactions for all relevant items
    interactableObjects.forEach((physical) => {
        interactions = [
            ...interactions,
            ...getPhysicalInteractions(physical, carriedItem, player.community_id, player.id)
        ];
    });
    // updates client only if interactions changes
    if (!areInteractionsEqual(lastInteractions, interactions) &&
        interactionCallback) {
        interactionCallback(interactions);
        lastInteractions = interactions;
        exports.currentInteractions = interactions;
    }
}
function setChatCompanionCallback(callback) {
    chatCompanionCallback = callback;
}
function setInteractionCallback(callback) {
    interactionCallback = callback;
}
function setFightOpponentCallback(callback) {
    fightOpponentCallback = callback;
}
function setInventoryCallback(callback) {
    inventoryCallback = callback;
}
function updateInventory() {
    if (inventoryCallback) {
        const storedItems = worldScene_2.world.getStoredItems();
        inventoryCallback(storedItems);
    }
}
function addNewHouse(scene, house) {
    const newHouse = new sprite_house_1.SpriteHouse(scene, house);
    worldScene_2.world.houses[newHouse.key] = newHouse;
}
function addNewMob(scene, mob) {
    if (!mob.position) {
        throw new Error('Mob has no position');
    }
    let newMob;
    if (worldScene_2.world.mobs[mob.id]) {
        newMob = worldScene_2.world.mobs[mob.id];
    }
    else {
        newMob = new sprite_mob_1.SpriteMob(scene, mob);
        worldScene_2.world.mobs[newMob.key] = newMob;
    }
    if (mob.id === worldMetadata_1.publicCharacterId) {
        console.log(`setting currentCharacter ${newMob.key}`, mob);
        newMob.attributeListeners.push((_mob, key, _delta) => {
            if (key === 'health' || key === 'gold' || key === 'speed') {
                (0, worldMetadata_1.refresh)();
            }
        });
        // This is the new "Setup camera section"
        scene.follow(newMob.sprite);
        scene.cameras.main.setBounds(0, 0, worldScene_2.world.worldWidth * worldScene_1.TILE_SIZE, worldScene_2.world.worldHeight * worldScene_1.TILE_SIZE);
        newMob.addCollisionListener(collisionListener);
        newMob.addMobRangeListener(mobRangeListener);
        const eyeColor = worldMetadata_1.currentCharacter.eyeColor;
        const bellyColor = worldMetadata_1.currentCharacter.bellyColor;
        const furColor = worldMetadata_1.currentCharacter.furColor;
        newMob.subtype = `${eyeColor}-${bellyColor}-${furColor}`;
    }
}
function setDate(date) {
    exports.fantasyDate = date;
}
function addNewItem(scene, item) {
    const newItem = new sprite_item_1.SpriteItem(scene, item);
    worldScene_2.world.items[newItem.key] = newItem;
    if (item.carried_by) {
        const mob = worldScene_2.world.mobs[item.carried_by];
        mob.carrying = newItem.key;
    }
}
