"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.world = exports.World = void 0;
const common_1 = require("@rt-potion/common");
class World {
    worldWidth;
    worldHeight;
    tiles;
    mobGrid;
    itemGrid;
    houseGrid;
    terrainTypes;
    mobs = {};
    items = {};
    storedItems = {};
    houses = {};
    pathFinder;
    // constructor
    constructor() { }
    addStoredItem(item) {
        console.log('Adding stored item:', item);
        this.storedItems[item.key] = item;
    }
    removeStoredItem(item) {
        console.log('Removing stored item:', item);
        delete this.storedItems[item.key];
    }
    getStoredItems() {
        console.log('Getting stored items:', Object.values(this.storedItems));
        return Object.values(this.storedItems);
    }
    load(worldDescription) {
        this.pathFinder = new common_1.PathFinder(worldDescription.tiles, worldDescription.terrain_types);
        this.worldWidth = worldDescription.tiles.length;
        this.worldHeight = worldDescription.tiles[0].length;
        console.log('world size', this.worldWidth, this.worldHeight);
        this.tiles = worldDescription.tiles;
        this.mobGrid = new Map();
        for (let x = 0; x < this.worldWidth; x++) {
            for (let y = 0; y < this.worldHeight; y++) {
                this.mobGrid.set(`${x},${y}`, []);
            }
        }
        this.itemGrid = new Map();
        this.terrainTypes = new Map();
        for (const td of worldDescription.terrain_types) {
            this.terrainTypes.set(td.id, td);
        }
        this.houseGrid = new Map();
    }
    tick(deltaTime) {
        for (const mob of Object.values(this.mobs)) {
            if (mob.dead) {
                mob.destroy(this);
            }
            else {
                mob.tick(this, deltaTime);
            }
        }
        for (const item of Object.values(this.items)) {
            item.tick(this, deltaTime);
        }
    }
    addHouseToGrid(house, position) {
        for (const pos of position) {
            this.houseGrid.set(`${pos.x},${pos.y}`, house);
        }
    }
    addMobToGrid(mob) {
        if (!mob.position) {
            throw new Error('Object has no position');
        }
        const coord = (0, common_1.floor)(mob.position);
        // Add to new position
        const newPos = `${coord.x},${coord.y}`;
        this.mobGrid.get(newPos)?.push(mob);
    }
    addItemToGrid(item) {
        if (!item.position) {
            throw new Error('Object has no position');
        }
        const coord = (0, common_1.floor)(item.position);
        this.itemGrid.set(`${coord.x},${coord.y}`, item);
    }
    removeItemFromGrid(item) {
        if (!item.position) {
            throw new Error('Object has no position');
        }
        const coord = (0, common_1.floor)(item.position);
        // Remove from old position
        const oldPos = `${coord.x},${coord.y}`;
        this.itemGrid.delete(oldPos);
    }
    removeMobFromGrid(mob) {
        if (!mob.position) {
            throw new Error('Object has no position');
        }
        const coord = (0, common_1.floor)(mob.position);
        // Remove from old position
        const oldPos = `${coord.x},${coord.y}`;
        this.mobGrid.get(oldPos)?.splice(this.mobGrid.get(oldPos).indexOf(mob), 1);
    }
    moveMob(mob, moveTo) {
        if (!mob.position) {
            throw new Error('Object has no position');
        }
        const flooredMob = (0, common_1.floor)(mob.position);
        const flooredMoveTo = (0, common_1.floor)(moveTo);
        if ((0, common_1.equals)(flooredMob, flooredMoveTo)) {
            return;
        }
        // Remove from old position
        const oldPos = `${flooredMob.x},${flooredMob.y}`;
        this.mobGrid.get(oldPos)?.splice(this.mobGrid.get(oldPos).indexOf(mob), 1);
        // Add to new position
        const newPos = `${flooredMoveTo.x},${flooredMoveTo.y}`;
        this.mobGrid.get(newPos)?.push(mob);
        //console.log('moved object', object, oldPos, newPos, x===newX, y===newY);
    }
    getItemAt(x, y) {
        const item = this.itemGrid.get(`${x},${y}`);
        return item || undefined;
    }
    getItemsAt(x, y, radius = 0) {
        // If radius is 0, we simply return objects at the exact position
        if (radius === 0) {
            const item = this.itemGrid.get(`${x},${y}`);
            return item ? [item] : [];
        }
        const itemsInRadius = [];
        // Define the bounds of the square area around the (x, y) point that could potentially contain objects
        const minX = Math.max(0, x - radius);
        const maxX = Math.min(this.worldWidth - 1, x + radius);
        const minY = Math.max(0, y - radius);
        const maxY = Math.min(this.worldHeight - 1, y + radius);
        // Iterate over all cells in the square area around (x, y)
        for (let i = minX; i <= maxX; i++) {
            for (let j = minY; j <= maxY; j++) {
                const item = this.itemGrid.get(`${i},${j}`);
                if (item) {
                    itemsInRadius.push(item);
                }
            }
        }
        return itemsInRadius;
    }
    getMobsAt(x, y, radius = 0) {
        // If radius is 0, we simply return objects at the exact position
        if (radius === 0) {
            return this.mobGrid.get(`${x},${y}`) || [];
        }
        const mobsInRadius = [];
        // Define the bounds of the square area around the (x, y) point that could potentially contain objects
        const minX = Math.max(0, x - radius);
        const maxX = Math.min(this.worldWidth - 1, x + radius);
        const minY = Math.max(0, y - radius);
        const maxY = Math.min(this.worldHeight - 1, y + radius);
        // Iterate over all cells in the square area around (x, y)
        for (let i = minX; i <= maxX; i++) {
            for (let j = minY; j <= maxY; j++) {
                const objectsAtPosition = this.mobGrid.get(`${i},${j}`) || [];
                for (const object of objectsAtPosition) {
                    // Calculate the Euclidean distance from (x, y) to the object's position
                    const distance = Math.sqrt((i - x) ** 2 + (j - y) ** 2);
                    if (distance <= radius) {
                        mobsInRadius.push(object);
                    }
                }
            }
        }
        return mobsInRadius;
    }
    generatePath(unlocks, from, to) {
        if (!this.pathFinder) {
            throw new Error('Pathfinder not initialized');
        }
        this.pathFinder.clearBlockingItems();
        for (const item of Object.values(this.items)) {
            if (!item) {
                continue;
            }
            if ((!item.itemType.walkable || item.lock) && item.position) {
                this.pathFinder.setBlockingItem(item.position.x, item.position.y, item.lock ? item.lock : 'block');
            }
        }
        return this.pathFinder.generatePath(unlocks, from, to);
    }
}
exports.World = World;
exports.world = new World();
