"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.speedUpCharacter = speedUpCharacter;
exports.restoreHealth = restoreHealth;
exports.persistWorldData = persistWorldData;
const playerToServer_1 = require("../services/playerToServer");
function speedUpCharacter() {
    console.log('Speeding up!');
    // Broadcast PlayerToServer message here
    (0, playerToServer_1.publishPlayerMessage)('cheat', { action: 'speed' });
}
function restoreHealth() {
    console.log('Health restored!!');
    // Broadcast PlayerToServer message here
    (0, playerToServer_1.publishPlayerMessage)('cheat', { action: 'health' });
}
function persistWorldData() {
    console.log('Attempting to save world data in Supabase');
    (0, playerToServer_1.publishPlayerMessage)('cheat', { action: 'save' });
}
