"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setupPlayerSubscriptions = setupPlayerSubscriptions;
const controller_1 = require("../world/controller");
const worldScene_1 = require("../scenes/worldScene");
const worldMetadata_1 = require("../worldMetadata");
function setupPlayerSubscriptions(player_channel, scene) {
    console.log('Setting up player subscriptions');
    function subscribeToPlayerChannel(event, callback) {
        player_channel.subscribe(event, (message) => {
            callback(message.data);
        });
    }
    subscribeToPlayerChannel('player_responses', (data) => {
        (0, controller_1.setResponses)(data.responses);
        (0, controller_1.setChatting)(true);
    });
    subscribeToPlayerChannel('player_attacks', (data) => {
        (0, controller_1.setAttacks)(data.attacks);
        (0, controller_1.setFighting)(true);
    });
    subscribeToPlayerChannel('chat_confirm', (data) => {
        const mob = worldScene_1.world.mobs[data.target];
        if (!mob) {
            console.error('Mob not found for chat confirm', data.target);
            return;
        }
        (0, controller_1.setChatting)(true);
    });
    subscribeToPlayerChannel('chat_close', () => {
        (0, controller_1.setChatting)(false);
    });
    subscribeToPlayerChannel('fight_close', () => {
        (0, controller_1.setFighting)(false);
    });
    subscribeToPlayerChannel('state', (data) => {
        console.log('Received state message', data);
        const mob_data = data.mobs;
        const item_data = data.items;
        const house_data = data.houses;
        for (const house of house_data) {
            if (!worldScene_1.world.houses[house.id]) {
                (0, controller_1.addNewHouse)(scene, house);
            }
        }
        for (const mob of mob_data) {
            if (!worldScene_1.world.mobs[mob.id]) {
                (0, controller_1.addNewMob)(scene, mob);
            }
        }
        for (const item of item_data) {
            if (!worldScene_1.world.items[item.id]) {
                (0, controller_1.addNewItem)(scene, item);
            }
        }
        (0, controller_1.setDate)(data.date);
        (0, controller_1.setGameState)('stateInitialized');
        (0, worldMetadata_1.refresh)();
    });
}
