"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoadWorldScene = exports.nameButtonHoverStyle = exports.buttonStyle = void 0;
const palette_swapper_1 = require("../sprite/palette_swapper");
const worldMetadata_1 = require("../worldMetadata");
const color_1 = require("../utils/color");
const ablySetup_1 = require("../services/ablySetup");
const controller_1 = require("../world/controller");
const config_1 = require("../config");
const validators_1 = require("../utils/validators");
exports.buttonStyle = {
    fontSize: '24px',
    color: '#ffffff',
    backgroundColor: '#28a745', // Green background
    padding: {
        x: 20,
        y: 10
    },
    align: 'center'
};
exports.nameButtonHoverStyle = {
    backgroundColor: '#138496' // Darker teal
};
class LoadWorldScene extends Phaser.Scene {
    constructor() {
        super({ key: 'LoadWorldScene' });
    }
    playerSprite;
    paletteSwapper = palette_swapper_1.PaletteSwapper.getInstance();
    lastAnimationKey = '';
    autoStart = false;
    /*
      Reset lastAnimationKey to the empty string to ensure that in the update function below
      the "if (this.lastAnimationKey === animationKey)" condition is not met.
      This ensures this character rerenders on the screen after game over and restart.
      */
    init(data) {
        this.lastAnimationKey = '';
        this.autoStart = data.autoStart;
    }
    preload() {
        this.load.image('frame', 'static/titleFrame.png');
        this.load.image('title', 'static/title.png');
        // Load menu music
        this.load.audio('menu_music', 'static/music/menu_music.mp3');
    }
    create() {
        if (!this.registry.has('music')) {
            this.registry.set('music', true);
        }
        // Play menu music
        if (this.registry.get('music') === true &&
            !this.sound.isPlaying('menu_music')) {
            // fairy music
            this.sound.add('menu_music', { loop: true, volume: 0.2 }).play();
        }
        // Add background image
        const background = this.add.image(0, 0, 'title');
        background.setOrigin(0, 0);
        background.setDisplaySize(config_1.SCREEN_WIDTH, config_1.SCREEN_HEIGHT);
        background.setDepth(-10);
        // Add frame
        const overlayImage = this.add.image(config_1.SCREEN_WIDTH / 2, config_1.SCREEN_HEIGHT / 2, 'frame');
        overlayImage.setScrollFactor(0);
        console.log('FrameScene created');
        const loadingMessage = this.add.text(config_1.SCREEN_WIDTH / 2, config_1.SCREEN_HEIGHT - 75, `Loading...`, {
            fontSize: '24px',
            color: '#000000'
        });
        loadingMessage.setOrigin(0.5, 0);
        // Add customization panel
        const panelX = 40;
        const panelY = 720 / 2 - 150;
        const panelWidth = 400;
        const panelHeight = 400;
        const customizationPanel = this.add.rectangle(panelX + panelWidth / 2, panelY, panelWidth, panelHeight, 0x000000, 0.7);
        customizationPanel.setOrigin(0.5, 0);
        customizationPanel.setDepth(-1);
        // Add panel title
        const panelTitle = this.add.text(panelX + panelWidth / 2, panelY + 20, 'Customize Your Character', {
            fontSize: '26px',
            color: '#ffffff'
        });
        panelTitle.setOrigin(0.5, 0.5);
        // Positions within the panel
        const panelContentX = panelX + 20;
        let currentY = panelY + 80;
        const spacingY = 50;
        // Character Name
        this.add.text(panelContentX, currentY, 'Character Name', {
            fontSize: '16px',
            color: '#ffffff'
        });
        const characterName = this.add.text(panelContentX + 200, currentY, worldMetadata_1.currentCharacter.name, {
            fontSize: '20px',
            color: '#ffffff',
            backgroundColor: '#17a2b8',
            padding: { x: 15, y: 8 }
        });
        characterName.setInteractive({ useHandCursor: true });
        characterName.setOrigin(0, 0.5);
        // Hover effects
        characterName.on('pointerover', () => {
            characterName.setStyle({ backgroundColor: '#138496' });
        });
        characterName.on('pointerout', () => {
            characterName.setStyle({ backgroundColor: '#17a2b8' });
        });
        characterName.on('pointerdown', () => {
            const userName = window.prompt('Please enter your name:');
            if (userName === null) {
                return;
            }
            const parsedName = (0, validators_1.parseName)(userName);
            if (parsedName) {
                (0, worldMetadata_1.changeName)(parsedName);
                characterName.setText(parsedName);
            }
            else {
                window.alert('Name must be between 1 and 10 characters inclusive. Whitespaces are trimmed.');
            }
        });
        // Update Y position
        currentY += spacingY;
        // Color Pickers
        const labelX = panelContentX + 200;
        const pickerX = labelX + 120;
        this.createColorPicker('Eye Color', labelX, pickerX, currentY, worldMetadata_1.currentCharacter.eyeColor, (color) => {
            worldMetadata_1.currentCharacter.eyeColor = color;
            (0, worldMetadata_1.saveColors)();
        });
        currentY += spacingY;
        this.createColorPicker('Belly Color', labelX, pickerX, currentY, worldMetadata_1.currentCharacter.bellyColor, (color) => {
            worldMetadata_1.currentCharacter.bellyColor = color;
            (0, worldMetadata_1.saveColors)();
        });
        currentY += spacingY;
        this.createColorPicker('Fur Color', labelX, pickerX, currentY, worldMetadata_1.currentCharacter.furColor, (color) => {
            worldMetadata_1.currentCharacter.furColor = color;
            (0, worldMetadata_1.saveColors)();
        });
        (0, ablySetup_1.setupAbly)()
            .then((worldID) => {
            (0, worldMetadata_1.setWorldID)(worldID);
            this.load.atlas('global-atlas', `../../../world_assets/${worldID}/global.png`, `../../../world_assets/${worldID}/global-atlas.json`);
            this.load.once('complete', () => {
                this.anims.create({
                    key: `test-idle`,
                    frames: this.anims.generateFrameNames('global-atlas', {
                        start: 1,
                        end: 4,
                        prefix: `player-idle-`
                    }),
                    frameRate: 5,
                    repeat: -1
                });
                // Remove the animation from the animation manager when the scene is stopped
                // so that on revive there is no warning for creation with a duplicate key.
                this.events.once('shutdown', () => {
                    this.anims.remove(`test-idle`);
                });
                // Position the character sprite
                this.playerSprite = this.add
                    .sprite(panelContentX, panelY + 100, 'global-atlas')
                    .setScale(6);
                this.playerSprite.setOrigin(0, 0);
            });
            this.load.start();
            // Skip the start button if traveling through a portal
            const traveling = sessionStorage.getItem('traveling_through_portal') === 'true';
            sessionStorage.setItem('traveling_through_portal', 'false');
            if (traveling) {
                this.scene.start('PauseScene');
                this.scene.start('WorldScene');
                this.scene.start('UxScene');
                this.scene.start('FrameScene');
                this.scene.start('MiniLeaderboardScene');
                (0, controller_1.setGameState)('worldLoaded');
            }
            // Create 'START!' button
            loadingMessage.destroy();
            if (this.autoStart) {
                this.startGame();
            }
            else {
                const startGame = this.add.text(config_1.SCREEN_WIDTH / 2, config_1.SCREEN_HEIGHT - 75, 'START!', exports.buttonStyle);
                startGame.setOrigin(0.5, 0);
                startGame.setInteractive({ useHandCursor: true });
                // Hover effects
                startGame.on('pointerover', () => {
                    startGame.setStyle(exports.nameButtonHoverStyle);
                });
                startGame.on('pointerout', () => {
                    startGame.setStyle(exports.buttonStyle);
                });
                startGame.on('pointerdown', () => {
                    this.sound.stopByKey('menu_music');
                    this.startGame();
                });
            }
        })
            .catch((_error) => {
            console.error('Error setting up Ably');
            // Handle Ably connection failure
        });
        //});
    }
    startGame() {
        this.scene.start('PauseScene');
        this.scene.start('WorldScene');
        this.scene.start('UxScene');
        this.scene.start('FrameScene');
        this.scene.start('MiniLeaderboardScene');
        (0, controller_1.setGameState)('worldLoaded');
    }
    update() {
        if (this.playerSprite && this.playerSprite.anims) {
            const eyeColor = worldMetadata_1.currentCharacter.eyeColor;
            const bellyColor = worldMetadata_1.currentCharacter.bellyColor;
            const furColor = worldMetadata_1.currentCharacter.furColor;
            const animationKey = `test-idle-${eyeColor}-${bellyColor}-${furColor}`;
            if (this.lastAnimationKey === animationKey) {
                return;
            }
            const furShade1Color = (0, color_1.darkenColor)(furColor, 25);
            const furShade2Color = (0, color_1.darkenColor)(furColor, 50);
            console.log('Redrawing character with animation key:', animationKey);
            this.paletteSwapper.swapAnimationPalette(this, 'test-idle', animationKey, 'global-atlas', {
                COLOR_1: eyeColor,
                COLOR_2: bellyColor,
                COLOR_3: furColor,
                COLOR_3_DARK: furShade1Color,
                COLOR_3_DARKER: furShade2Color
            });
            this.playerSprite.anims.play(animationKey);
            this.lastAnimationKey = animationKey;
        }
    }
    createColorPicker(labelText, xLabel, xPicker, y, defaultColor, callback) {
        // Create label text in Phaser
        const label = this.add.text(xLabel, y, labelText, {
            fontSize: '16px',
            color: '#ffffff'
        });
        label.setOrigin(0, 0.5); // Left-align the label text
        // Create the color input element as a DOM Element
        const colorInput = this.add.dom(xPicker, y - 10, 'input');
        colorInput.setOrigin(0, 0.5); // Left-align the color picker
        const inputElement = colorInput.node;
        inputElement.type = 'color';
        inputElement.value = (0, color_1.numberToHexString)(defaultColor);
        inputElement.classList.add('phaser-color-input');
        // Adjust styles
        const pickerWidth = 32; // Width of the color picker input
        inputElement.style.width = `${pickerWidth}px`;
        inputElement.style.height = '32px';
        inputElement.style.border = 'none';
        inputElement.style.padding = '0';
        inputElement.style.background = 'none';
        colorInput.setDepth(9999);
        // Listen for changes to the color input
        inputElement.addEventListener('input', (event) => {
            const color = event.target.value;
            callback((0, color_1.hexStringToNumber)(color));
        });
    }
}
exports.LoadWorldScene = LoadWorldScene;
