"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PauseScene = exports.GRAY = void 0;
exports.GRAY = 0x2f4f4f;
class PauseScene extends Phaser.Scene {
    constructor() {
        super({ key: 'PauseScene' });
    }
    preload() { }
    create() {
        const graphics = this.add.graphics();
        graphics.fillStyle(exports.GRAY, 1);
        graphics.fillRect(10, 10, this.game.scale.width - 20, this.game.scale.height * 0.5 - 10);
    }
}
exports.PauseScene = PauseScene;
