"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PortalLoadingScene = void 0;
const config_1 = require("../config");
class PortalLoadingScene extends Phaser.Scene {
    constructor() {
        super({ key: 'PortalLoadingScene' });
    }
    create() {
        // Add semi-transparent black background
        const overlay = this.add.rectangle(0, 0, config_1.SCREEN_WIDTH, config_1.SCREEN_HEIGHT / 2, 0x000000, 0.7);
        overlay.setOrigin(0, 0);
        // Add loading text
        const loadingText = this.add.text(config_1.SCREEN_WIDTH / 2, config_1.SCREEN_HEIGHT / 4, 'Loading World...', {
            fontSize: '32px',
            color: '#ffffff'
        });
        loadingText.setOrigin(0.5);
    }
}
exports.PortalLoadingScene = PortalLoadingScene;
